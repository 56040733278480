import { Link, useParams, useMatches } from 'remix';
import { PersonProfile } from 'wally-design-system';
import { useModal } from '~/context';

export default function PublicProfile() {
  const { openModal } = useModal();
  const params = useParams();

  const { pagename } = params

  const { profile } = useMatches().find(
    (m) => m.pathname === `/${pagename}`
  )?.data as {
    profile: any;
  };
  console.log(profile)
  const handleClick = () => {
    // openModal({})
    console.log('ClIikkingg')
  }
  return <PersonProfile profile={profile} component={Link} onClick={handleClick}/>
}